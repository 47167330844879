import { inject, Injectable } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { FormGroupValue } from '../form.utils';
import { ValidatorService } from '../form/validators/validator.service';
import { GiftRecipientInfoForm, GiftOptionForm, GiftRecipientForm } from './gift-recipient-info-form.model';

@Injectable({ providedIn: 'root' })
export class GiftRecipientInfoFormVm {
  fb = inject(FormBuilder);
  validator = inject(ValidatorService);

  create(partialValues: Partial<FormGroupValue<GiftRecipientForm>> = {}) {
    const form = this.fb.group<GiftRecipientForm>({
      account: this.fb.group<GiftRecipientInfoForm>({
        firstName: new FormControl('', {
          nonNullable: true,
          validators: Validators.compose([Validators.required, this.validator.isValidName()]),
          updateOn: 'blur',
        }),
        lastName: new FormControl('', {
          nonNullable: true,
          validators: Validators.compose([Validators.required, this.validator.isValidName()]),
          updateOn: 'blur',
        }),
        suffix: new FormControl('', { nonNullable: true }),
        address1: new FormControl('', {
          nonNullable: true,
          validators: Validators.compose([Validators.required]),
          updateOn: 'blur',
        }),
        address2: new FormControl('', { nonNullable: true }),
        city: new FormControl('', {
          nonNullable: true,
          validators: Validators.compose([Validators.required]),
          updateOn: 'blur',
        }),
        state: new FormControl('', {
          nonNullable: true,
          validators: Validators.compose([Validators.required]),
          updateOn: 'blur',
        }),
        zipcode: new FormControl('', {
          nonNullable: true,
          asyncValidators: [this.validator.zipCodeIsInClub()],
          validators: Validators.compose([
            Validators.required,
            this.validator.zipCodeFormat(),
            this.validator.zipCodeIsInRegion('state'),
          ]),
          updateOn: 'blur',
        }),
        birthday: new FormControl('', {
          nonNullable: true,
          validators: Validators.compose([this.validator.dateFormat()]),
          updateOn: 'blur',
        }),
        phone: new FormControl('', {
          nonNullable: true,
          validators: Validators.compose([this.validator.phoneNumber()]),
          updateOn: 'blur',
        }),
        email: new FormControl('', {
          nonNullable: true,
          asyncValidators: [this.validator.emailExistInClub()],
          validators: Validators.compose([Validators.required, this.validator.email()]),
          updateOn: 'blur',
        }),
        accidentMedicalPlan: new FormControl(false, { nonNullable: true }),
      }),
      options: this.fb.group<GiftOptionForm>({
        to: new FormControl('', {
          nonNullable: true,
          updateOn: 'blur',
        }),
        from: new FormControl('', {
          nonNullable: true,
          updateOn: 'blur',
        }),
        message: new FormControl('', {
          nonNullable: true,
          updateOn: 'blur',
        }),
        sendCardTo: new FormControl('purchaser', {
          nonNullable: true,
          updateOn: 'blur',
        }),
        autoRenew: new FormControl(false, {
          nonNullable: true,
        }),
      }),
    });

    form.patchValue(partialValues);

    return form;
  }
}
